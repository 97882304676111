import React, { useState } from 'react';
import { btnClass } from '../../themes';
import axios from 'axios';

const CommentProduct = ({ productDescription, setProductDescription, productIdCurrent, tableData, setTableData, setIsPopupOpen, position }) => {
    const [message, setMessage] = useState(null)
    const inputClass = 'w-5/6 border border-gray-300 text-slate-900 bg-gray-50 p-3 text-base'

    const handleSaveDescription = (e) => {               
        const updatedTableData = [...tableData];
        const index = updatedTableData.findIndex(item => item.id === productIdCurrent);

        
        if(index !== -1) {
            updatedTableData[index].description = productDescription;
            setTableData(updatedTableData);
        }

        const updatedProduct = {
            product_description: productDescription
        }

        axios.put(`${process.env.REACT_APP_SERVER_BACKEND}/faktury/produkt/${productIdCurrent}`, updatedProduct).then((res) => {
            setMessage('Zmiany zostały zapisane')
            setTimeout(() => setMessage(null), 2000);
        }).catch((error) => {
            console.log(error);
        });
    }
    return ( 
        <div 
            id="modelConfirm" 
            // className="fixed z-50 inset-0 bg-gray-900 bg-opacity-60 overflow-y-auto h-full w-full px-4"
        >
            <div 
                // className="relative top-40 mx-auto shadow-xl rounded-md bg-white max-w-md"
                className={`absolute shadow-xl drop-shadow-2xl rounded-md bg-white max-w-md`}
                style={{ top: position.top, right: position.right }}
            >

                <div className="flex justify-end py-1">
                    <button 
                        onClick={(e) => {
                            setIsPopupOpen(false);
                            const tableRow = document.querySelector(`tr[data-product_id="${productIdCurrent}"]`);
                            if (tableRow) { 
                                const tableCell = tableRow.querySelector('td.bg-yellow-300');
                                if (tableCell) {
                                    tableCell.classList.remove('bg-yellow-300');
                                }
                            }
                        } } 
                        type="button"
                        className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                    >
                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd"
                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                clip-rule="evenodd"></path>
                        </svg>
                    </button>
                </div>

                <div className="py-3 px-2 pt-0 text-right">     
                    {message && (
                        <div className="bg-green-200 text-green-800 p-4 rounded mb-4">
                            {message}
                        </div>
                    )}    
                    <textarea 
                        name="product_description" 
                        id="product_description" 
                        defaultValue={productDescription || ''} 
                        className={`w-full ${inputClass}`} 
                        onChange={(e) => setProductDescription(e.target.value)}
                    >
                    </textarea>
                    <button className={`text-sm ${btnClass}`} onClick={handleSaveDescription}>Zapisz zmiany</button>
                </div>

            </div>
        </div>
     );
}
 
export default CommentProduct;